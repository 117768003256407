import { call } from '@/bridge';
import { isEmpty } from '@/common/util';
import { useEffect, useRef } from 'react';

export function useViewWillReappear(fn: (data: any) => void) {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  useEffect(() => {
    const viewWillReappear = call('viewWillReappear', function (data: any) {
      if (!isEmpty(data)) {
        fnRef.current(data);
      }
    });
    return () => {
      try {
        viewWillReappear.then((res) => {
          res && res.unregister && res.unregister();
        });
      } catch (err) {
        console.error(err);
      }
    };
  }, []);
}
