import React, { Component, ReactNode } from 'react';
import '../../locales/runtime-init';
import { customErr, customWarn } from '@/common/exceptionHandler';
import ErrorInfo from '@/components/ErrorInfo';
import '@/common/mdap';
import '@/common/eruda';
// import './polyfill';
import '@/style/reset.scss';
import { formatMsg } from '@/locales';
import { setConfigurePage } from '@/common/biz';
import { goLogin } from '@/common/login';
import { showLoading } from '../BasicLoading';
import { fetchConfig } from '@/common/globalConfig';
import { showH5Popup } from '../PopupMessage';
import { callNavigate, call, callLinkReplace, showToast } from '@/bridge';
import { EPointerKey, reporter } from '@/common/pointerHandler';
// import { getLocalItem, setLocalItem } from '@/module/storage';
import { NetworkTipTextMap } from '@/common/constant';
import { shouldGoLogin as goLoginCheker } from '@/common/util';

import { TCurrentQuery, getUrlParamByKey2, getUrlProductId, parseUrlQuery } from '@/module/url';
import { ContactType, openExternalLink } from '@/bridge/helper';
// import { getLocalItem, removeItem } from '@/module/storage';

export interface IBaseComponentProps {
  children: ReactNode;
  requireParams?: TCurrentQuery[];
}

interface IState {
  hasError: boolean;
  errorText?: string;
  shouldGoLogin: boolean;
  isLoadedLangs: boolean;
  missRequireParams: boolean;
}

export interface ICommonInterface {
  showError?: (args?: { errorText?: string; error?: any } | any) => void;
}
fetchConfig();
// type TConsole = 'info' | 'log' | 'warn' | 'error';
reporter(EPointerKey.HTML_START, {
  // @ts-ignore
  report_time: window.htmlStartTime,
  locale: window.INS_LOCALE,
  // @ts-ignore
  locale_real: window.INS_LOCALE_REAL,
  from: getUrlParamByKey2('from'),
  ins_level: getUrlParamByKey2('ins_level'),
  product_id: getUrlProductId() || undefined,
  ua: window.navigator.userAgent,
  time: Date.now(),
  release_tag: process.env.RELEASE_TAG,
  pp: getUrlParamByKey2('pp'),
});

export default class BaseComponent extends Component<IBaseComponentProps, IState> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      isLoadedLangs: true,
      errorText: '',
      shouldGoLogin: goLoginCheker(),
      missRequireParams: this.checkParams(),
    };
    setConfigurePage({});
    this.langIntervalFun();
    // this.interceptConsole();
    // this.checkParams();
    // @ts-ignore
    window.INS_BODY_TOUCHED = false;
    this.bodyTouch = this.bodyTouch.bind(this);
    this.reportLaunchTime();
    // this.reportPerformance();

    this.adjustHref();
  }

  componentDidMount() {
    // NOTE 进入页面先读取shopee_user_id 这个cookie，判断是否已有itoken，没有的话，直接登陆
    // 只在seainsure域名生效
    const { shouldGoLogin } = this.state;
    if (shouldGoLogin) {
      goLogin();
    }

    // 注入全局的a标签点击的拦截，禁止默认行为，改为callWebview
    document.body.addEventListener('click', function (event: Event) {
      const target = event.target as HTMLLinkElement;
      // 如果点击的是a标签
      if (target && target.nodeName.toLowerCase() === 'a') {
        event.preventDefault();

        const url = target.getAttribute('href');
        if (!url) return;
        const type = target.getAttribute('data-type');
        // 在shopeeApp中，则通过callWebView打开，否则，使用window.open

        if (type === 'baseJump') {
          callNavigate(undefined, url);
        } else if (type === 'phone') {
          openExternalLink(ContactType.phone_number, url);
        }
      }
    });
    document.body.addEventListener('click', this.bodyTouch);
    document.body.addEventListener('touchstart', this.bodyTouch);
    call('enableRefreshOnWebViewBlankWhenViewDidAppear', { enabled: false });
  }

  componentDidCatch(error: Error, errorInfo: any) {
    customErr('baseComponentCatch', { error, errorInfo });
  }
  componentWillUnmount(): void {
    document.body.removeEventListener('click', this.bodyTouch);
    document.body.removeEventListener('touchstart', this.bodyTouch);
  }
  langsIntervalId: any;
  reportLaunchTime() {
    // const key = LAST_PAGE_TIME + location.pathname;
    // getLocalItem(key).then((v) => {
    //   if (!v) return;
    //   const origin = window.performance.timing.navigationStart;
    //   const round = Math.round;
    //   const jump_init = round(origin - v);
    //   custom(`last_page_time${jump_init <= 0 ? '_err' : ''}`, {
    //     last_time: round(v),
    //     duration: round(Date.now() - v),
    //     jump_init,
    //     performance: round(Date.now() - origin),
    //     time_origin: window.performance.timeOrigin,
    //     request_start: window.performance.timing.requestStart,
    //     fetch_start: window.performance.timing.fetchStart,
    //   });
    //   removeItem(key, 'local');
    // });
  }
  // 检查是否缺失必选参数
  checkParams() {
    const requireParams = this.props.requireParams;
    if (!requireParams) return false;
    const params = parseUrlQuery();
    const missParams: string[] = [];
    requireParams.forEach((val) => {
      if (!params[val]) {
        missParams.push(val);
      }
    });

    if (missParams.length > 0) {
      // console.error('missRequireParams', {
      //   missParams,
      // });
      customErr('missRequireParams', { data: missParams });
      return true;
    }
    return false;
  }
  // async reportPerformance() {
  //   let canPer;
  //   const cacheKey = '__support_performance';
  //   const isSupport = await getLocalItem(cacheKey);
  //   if (window.performance && typeof window.performance.getEntriesByType == 'function') {
  //     canPer = '1';
  //   } else {
  //     canPer = '2';
  //   }
  //   if (!isSupport) {
  //     setLocalItem(cacheKey, canPer);
  //   }
  //   let ob;
  //   if (
  //     window.PerformanceObserver &&
  //     window.PerformanceObserver.supportedEntryTypes &&
  //     window.PerformanceObserver.supportedEntryTypes.indexOf('resource') >= 0
  //   ) {
  //     ob = '1';
  //   } else {
  //     ob = '2';
  //   }
  //   // @ts-ignore
  //   const f = window.fetch ? '1' : '2';
  //   captureSimple('performance_monitor', isSupport ? '2' : '1', 'warn', {
  //     pv: canPer,
  //     dev: !isSupport ? canPer : '3',
  //     pvob: ob,
  //     devob: !isSupport ? ob : '3',
  //     fetch: f,
  //     devfetch: !isSupport ? f : '3',
  //   });
  // }
  langIntervalFun() {
    const startTime = Date.now();
    const self = this;
    PFC.getPFCAsync('transify')
      .then(() => {
        if (!PFC.getPFC('transify')) throw new Error('transify is empty');
      })
      .catch(() => {
        // 加载大于10s
        customWarn('transifyLoadError', { duration: Date.now() - startTime });
        // clearInterval(this.langsIntervalId);
        showH5Popup({
          title: NetworkTipTextMap.title[process.env.REGION] || 'Network Error',
          content:
            NetworkTipTextMap.content[process.env.REGION] ||
            'Network Error. Please Try Again Later',
          okText: NetworkTipTextMap.retry[process.env.REGION] || 'Retry',
          cancelText: '',
          onOk: () => {
            location.reload();
          },
        });
        self.setState({
          isLoadedLangs: false,
        });
      });
  }
  adjustHref() {
    // 校准参数，顺便上报，如果放在html，上报不了，没法驱动他们改页面
    const href = location.href;
    const questionArr = href.split('?');
    if (questionArr.length > 2) {
      console.error('page_link_error', {
        code: 3,
      });
      callLinkReplace(`${questionArr[0]}?${questionArr.slice(1).join('&')}`);
    }
    // https://protection.shopee.com.my/ec/ec-pdp.html?from=pdp&amp;product_id=1753020014929152041
    if (href.indexOf('&amp;') > 0) {
      callLinkReplace(location.href.replace(/&amp;/g, '&'));
    }
  }
  bodyTouch() {
    // @ts-ignore
    window.INS_BODY_TOUCHED = true;
  }
  // parseArgs(args: string | any[]) {
  //   if (typeof args != 'object') {
  //     return args;
  //   }
  //   if (!Array.isArray(args)) {
  //     args = [args];
  //   }
  //   return args.map((d) => {
  //     if (!d) return '';
  //     if (typeof d == 'function') return d.toString();
  //     if (d instanceof Error) {
  //       return `${d.name};${d.message};${d.stack}`;
  //     }
  //     if (d instanceof Object) return JSON.stringify(d);
  //     return d;
  //   });
  // }
  // interceptConsole() {
  //   const fn: TConsole[] = ['warn', 'error'];
  //   fn.forEach((key) => {
  //     const origin = console[key];
  //     console[key] = (...args) => {
  //       origin(...args);
  //       const level = key == 'log' ? 'info' : key;
  //       capture({
  //         name: this.parseArgs(args[0]) as string,
  //         message: (this.parseArgs(args) as []).join(' , '),
  //         data: {
  //           level,
  //         },
  //       });
  //     };
  //   });
  // }
  retry = () => {
    this.setState({
      hasError: false,
    });
  };

  showError = (
    args: { errorText?: string; message?: string; msg?: string; error?: any } | string = {},
  ) => {
    let msg = '';
    if (typeof args == 'string') {
      msg = args;
    } else {
      msg =
        args.errorText ||
        args.message ||
        args.msg ||
        formatMsg('common.component.basecomponent.errortext');
    }
    this.setState({
      hasError: true,
      errorText: msg,
    });
    // console.error('baseComponentError', args || 'empty');
  };

  render() {
    const { hasError, errorText, shouldGoLogin, isLoadedLangs, missRequireParams } = this.state;
    if (hasError && !window.isJumpingLock) {
      return <ErrorInfo retry={this.retry} errorText={errorText} />;
    }

    if (window.isJumpingLock) {
      showLoading();
      return;
    }

    if (shouldGoLogin) {
      showLoading();
      return;
    }
    if (!isLoadedLangs) {
      return;
    }

    if (missRequireParams) {
      showToast(formatMsg('abnormal.access'));
      setTimeout(() => {
        window.location.replace('/common/home.html');
      }, 3000);
      // 告警
      console.warn('link_miss_params_error', {
        requireParams: this.props.requireParams,
      });
      return;
    }

    const { children } = this.props;
    return React.cloneElement(children as any, {
      showError: this.showError,
    });
  }
}
